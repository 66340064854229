.workers {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.workers__available {
  color: #93bad9;
  font-size: 1.1rem;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
  text-align: center;
  padding: 8px 16px;
  background: rgba(27, 59, 80, 0.6);
  border-radius: 8px;
  border: 1px solid rgba(147, 186, 217, 0.3);
  backdrop-filter: blur(5px);
}

/* Media queries for responsive design */
@media screen and (max-width: 1440px) {
  .workers__available {
    font-size: 1rem;
    padding: 6px 12px;
  }
}

@media screen and (max-width: 1024px) {
  .workers__available {
    font-size: 0.9rem;
    padding: 5px 10px;
  }
}

@media screen and (max-width: 768px) {
  .workers__available {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
}

.tasks {
  display: flex;
  gap: 15px;
  padding: 15px;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 100%;
}

.task {
  position: relative;
  width: 280px;
  background: rgba(27, 59, 80, 0.7);
  border: 1px solid rgba(147, 186, 217, 0.3);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.task__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    135deg,
    rgba(27, 59, 80, 0.95) 0%,
    rgba(27, 59, 80, 0.85) 100%
  );
}

.task__top-section {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
}

.task__image-container {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
  flex-shrink: 0;
}

.task__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.task__image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(27, 59, 80, 0.8) 100%
  );
}

.task__info {
  padding: 0 15px 15px 15px;
}

.task__header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.task__title {
  font-family: "Rubik Distressed", cursive;
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
}

.task__effects {
  font-size: 0.85rem;
  color: #93bad9;
  font-weight: 500;
}

@media (max-width: 1440px) {
  .tasks {
    gap: 8px;
    padding: 8px;
  }

  .task {
    width: 180px;
    height: 260px;
  }

  .task__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }

  .task__top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 10px;
  }

  .task__image-container {
    position: relative;
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 8px;
    flex-shrink: 0;
  }

  .task__header {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .task__title {
    font-size: 1.1rem;
  }

  .task__effects {
    font-size: 0.9rem;
  }

  .task__info {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .task__drop-zone {
    padding: 0;
  }

  .task__drop-zone-inner {
    width: 40px;
    height: 40px;
  }

  .task__assigned-crew {
    font-size: 1.2rem;
  }
}

@media (max-width: 1200px) {
  .tasks {
    gap: 6px;
    padding: 6px;
  }

  .task {
    width: 160px;
    height: 240px;
  }

  .task__content {
    padding: 10px;
  }

  .task__top-section {
    gap: 8px;
  }

  .task__image-container {
    width: 55px;
    height: 55px;
  }

  .task__header {
    gap: 6px;
  }

  .task__title {
    font-size: 1rem;
  }

  .task__effects {
    font-size: 0.85rem;
  }

  .task__drop-zone-inner {
    width: 38px;
    height: 38px;
  }

  .task__assigned-crew {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  /* iPad and similar */
  .tasks {
    gap: 4px;
    padding: 4px;
  }

  .task {
    width: 140px;
    height: 220px;
  }

  .task__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .task__top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 8px;
  }

  .task__image-container {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 8px;
    flex-shrink: 0;
  }

  .task__header {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  .task__title {
    font-size: 1rem;
  }

  .task__effects {
    font-size: 0.8rem;
  }

  .task__info {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .task__drop-zone {
    padding: 0;
  }

  .task__drop-zone-inner {
    width: 35px;
    height: 35px;
  }

  .task__assigned-crew {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .task {
    width: 130px;
    height: 115px;
  }
}

@media (max-width: 480px) {
  .tasks {
    gap: 3px;
    padding: 3px;
  }

  .task {
    width: 120px;
    height: 110px;
  }

  .task__image-container {
    width: 28px;
    height: 28px;
  }
}

.task:hover {
  transform: translateY(-2px);
  border-color: rgba(147, 186, 217, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 0 30px rgba(147, 186, 217, 0.1);
}

.task:hover .task__image {
  transform: scale(1.05);
}

.task__drop-zone {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.task__drop-zone-inner {
  position: relative;
  width: 50px;
  height: 50px;
}

.task__drop-zone-placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px dashed rgba(147, 186, 217, 0.4);
  background: rgba(27, 59, 80, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: rgba(147, 186, 217, 0.6);
  text-align: center;
  padding: 5px;
  line-height: 1.2;
  transition: all 0.3s ease;
}

.task__assigned-crew {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(147, 186, 217, 0.2);
  border: 2px solid rgba(147, 186, 217, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik Distressed", cursive;
  font-size: 1.4rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 20px rgba(147, 186, 217, 0.2),
    0 0 15px rgba(0, 0, 0, 0.3);
}

.task__assigned-crew:hover {
  background: rgba(147, 186, 217, 0.3);
  border-color: rgba(147, 186, 217, 0.8);
}

.task__remove-crew {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background: rgba(27, 59, 80, 0.9);
  border: 2px solid rgba(147, 186, 217, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #93bad9;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.2s ease;
}

.task__assigned-crew:hover .task__remove-crew {
  opacity: 1;
  transform: scale(1);
}

.task__remove-crew:hover {
  background: rgba(147, 186, 217, 0.3);
  color: #ffffff;
}

.task__drop-zone[data-dragging-over="true"] .task__drop-zone-placeholder {
  border-color: rgba(147, 186, 217, 0.8);
  background: rgba(147, 186, 217, 0.2);
}

.task::after {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(
      circle at 70% 20%,
      rgba(0, 0, 0, 0.1) 0%,
      transparent 50%
    ),
    radial-gradient(circle at 30% 80%, rgba(0, 0, 0, 0.1) 0%, transparent 50%);
  pointer-events: none;
}

.task__frost {
  border-radius: 12px;
}

.task--locked {
  opacity: 0.7;
}

.task--locked .task__image {
  filter: grayscale(1);
}

.task--delegated {
  cursor: default;
  transform: none !important;
}

.task--delegated:hover {
  transform: none !important;
  border-color: rgba(147, 186, 217, 0.3);
  box-shadow: none;
}

.task--delegated .task__assigned-crew {
  cursor: default;
  background: rgba(147, 186, 217, 0.4);
}

.task--delegated .task__assigned-crew:hover {
  background: rgba(147, 186, 217, 0.4);
  border-color: rgba(147, 186, 217, 0.6);
}

.task__delegated-overlay {
  position: absolute;
  inset: 0;
  background: rgba(27, 59, 80, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  animation: delegatedFadeIn 0.5s ease-out;
}

.task__delegated-icon {
  font-size: 2rem;
  color: #93bad9;
  text-shadow: 0 0 15px rgba(147, 186, 217, 0.5);
  animation: delegatedCheckmark 0.5s ease-out;
}

@keyframes delegatedFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes delegatedCheckmark {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Add responsive styles for the delegated state */
@media (max-width: 1024px) {
  .task__delegated-icon {
    font-size: 1.5rem;
  }
}

.tasks__available-workers {
  position: absolute;
  top: -40px;
  right: 20px;
  color: #93bad9;
  font-size: 1.1rem;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
  background: rgba(27, 59, 80, 0.6);
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid rgba(147, 186, 217, 0.3);
  backdrop-filter: blur(5px);
}

.task__crew-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background: rgba(27, 59, 80, 0.3);
  border-radius: 8px;
  border: 1px solid rgba(147, 186, 217, 0.2);
}

.task__crew-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(147, 186, 217, 0.4);
  background: rgba(27, 59, 80, 0.6);
  color: #93bad9;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  line-height: 1;
}

.task__crew-button:hover:not(:disabled) {
  border-color: rgba(147, 186, 217, 0.8);
  background: rgba(147, 186, 217, 0.2);
  transform: scale(1.1);
}

.task__crew-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.task__crew-count {
  min-width: 30px;
  text-align: center;
  font-family: "Rubik Distressed", cursive;
  font-size: 1.4rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.5);
}

/* Media queries for responsive design */
@media (max-width: 1440px) {
  .tasks__available-workers {
    font-size: 1rem;
    top: -35px;
  }

  .task__crew-button {
    width: 25px;
    height: 25px;
    font-size: 1rem;
  }

  .task__crew-count {
    font-size: 1.2rem;
  }
}

@media (max-width: 1024px) {
  .tasks__available-workers {
    font-size: 0.9rem;
    top: -32px;
    padding: 6px 12px;
  }

  .task__crew-button {
    width: 22px;
    height: 22px;
    font-size: 0.9rem;
  }

  .task__crew-count {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .tasks__available-workers {
    font-size: 0.8rem;
    top: -30px;
    padding: 4px 10px;
  }

  .task__crew-button {
    width: 20px;
    height: 20px;
    font-size: 0.8rem;
  }

  .task__crew-count {
    font-size: 1rem;
  }
}

/* Remove old drag-and-drop styles */
.task__drop-zone,
.task__drop-zone-inner,
.task__drop-zone-placeholder,
.task__assigned-crew,
.task__remove-crew {
  display: none;
}

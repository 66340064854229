.crew-messages {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  max-height: 80vh;
  z-index: 1000;
  pointer-events: none;
}

.crew-messages__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(80vh - 40px);
  overflow-y: auto;
  padding-right: 10px;
  scrollbar-width: thin;
  scrollbar-color: rgba(147, 186, 217, 0.3) transparent;
}

/* Webkit scrollbar styling */
.crew-messages__container::-webkit-scrollbar {
  width: 6px;
}

.crew-messages__container::-webkit-scrollbar-track {
  background: transparent;
}

.crew-messages__container::-webkit-scrollbar-thumb {
  background-color: rgba(147, 186, 217, 0.3);
  border-radius: 3px;
}

.crew-messages__container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(147, 186, 217, 0.5);
}

.crew-message {
  position: relative;
  background: rgba(27, 59, 80, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(147, 186, 217, 0.3);
  padding: 12px;
  color: #93bad9;
  font-size: 0.9rem;
  line-height: 1.4;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
  opacity: 0;
  transform: translateX(20px);
  animation: messageSlide 0.3s ease forwards;
  pointer-events: auto;
}

.crew-message__content {
  position: relative;
  z-index: 1;
}

.crew-message__frost {
  position: absolute;
  inset: 0;
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    rgba(147, 186, 217, 0.1) 0%,
    transparent 100%
  );
  pointer-events: none;
}

@keyframes messageSlide {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .crew-messages {
    width: 250px;
  }
}

@media (max-width: 480px) {
  .crew-messages {
    width: 200px;
  }
}

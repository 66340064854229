/* Base container */
.antarctica-left-bar {
  width: 60px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  overflow: hidden; /* Prevent outer scrolling */
}

/* Scrollable container for all content */
.antarctica-left-bar__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(147, 186, 217, 0.3) transparent;
}

/* Webkit scrollbar styling */
.antarctica-left-bar__content::-webkit-scrollbar {
  width: 4px;
}

.antarctica-left-bar__content::-webkit-scrollbar-track {
  background: transparent;
}

.antarctica-left-bar__content::-webkit-scrollbar-thumb {
  background-color: rgba(147, 186, 217, 0.3);
  border-radius: 4px;
}

.antarctica-left-bar__content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(147, 186, 217, 0.5);
}

.antarctica-left-bar__portraits {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.left-bar-portrait {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba(147, 186, 217, 0.3);
  overflow: hidden;
  transition: all 0.3s ease;
}

.left-bar-portrait:hover {
  border-color: rgba(147, 186, 217, 0.8);
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(147, 186, 217, 0.2),
    0 0 20px rgba(147, 186, 217, 0.1);
}

.left-bar-portrait__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal-divider {
  width: 40px;
  height: 2px;
  margin: 15px auto;
  background: linear-gradient(
    to right,
    transparent,
    rgba(147, 186, 217, 0.8),
    transparent
  );
  position: relative;
}

/* Add a glow effect */
.horizontal-divider::after {
  content: "";
  position: absolute;
  inset: -1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(147, 186, 217, 0.3),
    transparent
  );
  filter: blur(2px);
}

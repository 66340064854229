.tactics {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tactics__content {
  width: 100%;
  height: calc(
    100vh - 140px
  ); /* Adjusted to account for top and bottom bars while maximizing space */
  background: rgba(27, 59, 80, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(147, 186, 217, 0.3);
  box-shadow: inset 0 0 100px rgba(147, 186, 217, 0.1),
    0 0 30px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(147, 186, 217, 0.3) transparent;
  margin: 10px auto; /* Center the content with some vertical margin */
}

.tactics__content::-webkit-scrollbar {
  width: 8px;
}

.tactics__content::-webkit-scrollbar-track {
  background: transparent;
}

.tactics__content::-webkit-scrollbar-thumb {
  background-color: rgba(147, 186, 217, 0.3);
  border-radius: 4px;
  border: 2px solid transparent;
}

.tactics__content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(147, 186, 217, 0.5);
}

.tactics__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
}

.tactics__titles {
  flex: 1;
  padding-right: 40px;
}

.tactics__character {
  transform: scaleX(-1);
  flex-shrink: 0;
  width: 120px;
}

.tactics__title {
  font-family: "Rubik Distressed", cursive;
  color: #ffffff;
  font-size: 2.5rem;
  margin: 0 0 10px;
  text-shadow: 0 0 20px rgba(147, 186, 217, 0.3);
}

.tactics__subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  line-height: 1.4;
  margin: 0;
  max-width: 600px;
}

/* Add a subtle frost effect to the edges */
.tactics__content::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(
    135deg,
    rgba(147, 186, 217, 0.1) 0%,
    transparent 100%
  );
  pointer-events: none;
}

.tactics__rules {
  margin-top: 20px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid rgba(147, 186, 217, 0.2);
}

.tactics__rule {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.5;
  margin: 8px 0;
  font-family: "Courier New", monospace;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
}

.tactics__rule:first-child {
  margin-top: 0;
}

.tactics__rule:last-child {
  margin-bottom: 0;
}

.tactics__cards {
  margin-top: 10px;
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 0;
  flex: 1;
}

.tactics__card {
  width: 180px;
  height: 390px;
  background: rgba(27, 59, 80, 0.7);
  border: 1px solid rgba(147, 186, 217, 0.3);
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  position: relative;
}

.tactics__card-number {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b3b50;
  font-family: "Rubik Distressed", cursive;
  font-size: 18px;
  margin: 0 auto 10px;
}

.tactics__card::before {
  display: none;
}

.tactics__card:hover {
  transform: translateY(-2px);
  border-color: rgba(147, 186, 217, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 0 30px rgba(147, 186, 217, 0.1);
}

.tactics__card-title {
  font-family: "Rubik Distressed", cursive;
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0 0 10px 0;
  text-align: center;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
}

.tactics__card-content {
  color: #93bad9;
  font-size: 0.85rem;
  line-height: 1.4;
  text-align: center;
  font-weight: 500;
  margin-top: auto;
  padding-bottom: 10px;
}

.tactics__card-divider {
  width: 80%;
  height: 1px;
  background: rgba(147, 186, 217, 0.3);
  margin: 10px auto;
}

/* Update the card rendering in the component */
.tactics__card-main {
  color: #93bad9;
  font-size: 0.85rem;
  line-height: 1.4;
  text-align: center;
  font-weight: 500;
  margin-top: auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.tactics__card-main p {
  margin: 0;
  padding: 10px 0;
}

.tactics__card-divider {
  width: 80%;
  height: 1px;
  background: rgba(147, 186, 217, 0.3);
  margin: 0 auto;
}

/* Add responsive styles to match Tasks.css */
@media (max-width: 1440px) {
  .tactics__card {
    width: 180px;
    height: 390px;
  }
}

@media (max-width: 1200px) {
  .tactics__card {
    width: 240px;
    height: 300px;
  }
}

@media (max-width: 1024px) {
  .tactics__card {
    width: 140px;
    height: 330px;
  }

  .tactics__card-title {
    font-size: 1rem;
  }

  .tactics__card-content {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .tactics__card {
    width: 130px;
    height: 172px;
  }
}

@media (max-width: 480px) {
  .tactics__card {
    width: 120px;
    height: 165px;
  }
}

.tactics__card--task {
  border-color: #ffd700; /* Golden border for task cards */
  display: flex;
  flex-direction: column;
}

.tactics__card--task .tactics__card-content {
  margin-top: auto; /* This pushes the content to the bottom */
  margin-bottom: 10px;
}

/* Add specific layout for task cards */
.tactics__card--task .tactics__card-main {
  margin-top: auto; /* This pushes the content to the bottom */
  margin-bottom: 10px;
}

/* Adjust the task image container spacing */
.tactics__card-task-image {
  width: 64px;
  height: 64px;
  margin: 8px auto;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Prevent image from shrinking */
}

/* Ensure the title stays close to the image */
.tactics__card--task .tactics__card-title {
  margin-top: 8px;
  margin-bottom: auto; /* Push content below down */
}

.tactics__card-task-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #ffd700;
  color: #1b3b50;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.tactics__card-task-image {
  width: 64px;
  height: 64px;
  margin: 8px auto;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tactics__card-task-image img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

/* Adjust card content spacing for task cards */
.tactics__card--task .tactics__card-content {
  margin-top: 8px;
}

.tactics__card--score {
  border-color: #4caf50; /* Green border for score card */
}

.tactics__card-score-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.tactics__card--rule {
  border-color: #ff9800; /* Orange border for rule cards */
}

.tactics__card-rule-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.tactics__card--advice {
  border-color: #2196f3; /* Blue border for advice cards */
}

.tactics__card-advice-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #2196f3;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}

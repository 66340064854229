.antarctica-game-area {
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
  overflow: visible;
  position: relative;
  box-shadow: inset 0 0 100px rgba(147, 186, 217, 0.1);
}

/* Add a frost effect to the edges */
.antarctica-game-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, 
    transparent,
    rgba(147, 186, 217, 0.5),
    transparent
  );
  z-index: 1;
}
  